<template>
  <v-card>
    <v-app-bar flat :dense="denseToolbar">
      <slot name="toolbar" />
    </v-app-bar>
    <v-card-text style="height: 100%">
      <slot name="content" />
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn text color="success" @click="$emit('save')" :disabled="!canSave">{{
        saveText
      }}</v-btn>
      <v-btn text color="error" @click="$emit('delete')" v-if="canDelete"
        >Löschen</v-btn
      >
      <v-spacer />
      <v-btn text @click="$emit('abort')">{{ abortText }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'EditCard',
  props: {
    canSave: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    saveText: {
      type: String,
      default: 'Speichern',
    },
    abortText: {
      type: String,
      default: 'Abbrechen',
    },
    denseToolbar: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
